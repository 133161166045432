* {
  //box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  background: $dark-blue;
  font-family: Helvetica, Arial, sans-serif;
  font-size: $m-size;
  width: 100%;
}

button {
  cursor: pointer;
}

button:disabled {
  cursor: default;
}