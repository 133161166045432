.Board {
  position: relative;
  margin: 0 auto;
  background-color: #84b2ff;
  background-image:
    linear-gradient(#333 1px, transparent 1px),
    linear-gradient(90deg, #333 1px, transparent 1px);
  z-index: 1;
  border-radius: 10px;
  
  clip-path: polygon(0.2% 0, 100% 0, 100% 100%, 0.2% 100%);
}

.Cell {
  background: white;
  position: absolute;
}