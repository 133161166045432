.header {
  background: $off-black;
  color: white;
  margin-bottom: $m-size;
  padding: $m-size 0;
}

// BEM block element modifier naming convention
.header__title {
  font-size: $l-size;
  margin: 0;
}

.header__subtitle {
  color: $off-white;
  font-size: $m-size;
  font-weight: 500;
  margin: 0;
}

@media (min-width: $desktop-breakpoint) {
  .header {
    margin-bottom: $xl-size;
  }
}