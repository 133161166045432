.cd-fixed-bg {
  min-height: 100vh;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center center;
  z-index: 1;
}

@supports (-webkit-overflow-scrolling: touch) {
  .cd-fixed-bg {
    min-height: 100vh;
    background-size: cover;
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-position: center center;
    z-index: 1;
  }
}

body {
  background-color: black;
  overflow-x: hidden;
}

.cd-fixed-bg.cd-fixed-bg--1 {
  background-image: url('../assets/bike-small.jpg');
}

.cd-fixed-bg.cd-fixed-bg--2 {
  background-image: url('../assets/blue-flower.jpg');
}

.cd-fixed-bg.cd-fixed-bg--3 {
  background-image: url('../assets/jelly2.jpg');
}

.cd-fixed-bg.cd-fixed-bg--4 {
  background-image: url('../assets/sky-of-stars.jpg');
}

.cd-scrolling-bg {
  min-height: 50vh;
}

.cd-scrolling-bg--color-1 {
  background-color: var(--main-bg-color);
  background-image: url('../assets/swirls.png');
  background-repeat: repeat-x;
}
