@import '_background';
* {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  height: 100%;
}

body {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 1.6rem; 
  background-color: var(--main-bg-color);
  overflow-x: hidden;
  --main-bg-color: #40758e; 
  --main-bg-color: #105870; 
}

button {
  cursor: pointer;
}

img {
  cursor: pointer;
}

button:disabled {
  cursor: default;
}

.is-active {
  font-weight: bold;
}